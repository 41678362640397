<template>
    <div>
        <v-card>
            <v-card-actions class="mx-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3 mb-3 white--text" small color="green" depressed @click="export2xls">Export to XLS</v-btn>
            </v-card-actions>
            <v-card-subtitle>
                <div style="text-align:center; font-weight:bold; font-size:16px; padding:10px">FORM 003 - Jumlah Permintaan Buah Mingguan</div>

                <v-row class="compact-form my-2">
                    <v-col cols="1">
                        Tanggal
                    </v-col>
                    <v-col cols="3" class="">
                        <v-menu v-model="dateMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="reportDate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="reportDate" :allowed-dates="allowedDates"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="1">
                        Category
                    </v-col>
                    <v-col cols="3" class="">
                        <v-select :items="categories" item-text="desc" item-value="code" v-model="reportCat" outlined dense hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-btn depressed color="primary" @click="runReport">Run</v-btn>
                    </v-col>
                </v-row>                

            </v-card-subtitle>

            <v-card-text class="ml-3 mr-3">

                <div id="printresep" style="overflow:auto">
                    <!-- <table style="width:98%; margin: 0px;" class="pdf-item" ref="tableBOM"> -->
                    <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th rowspan="2"><b>Kode</b></th>
                                    <th rowspan="2"><b>Nama Bahan</b></th>
                                    <th colspan="5" style="text-align: center"><b>Kebutuhan (Kg)</b></th>
                                </tr>
                                <tr>
                                    <th><b>Periode 1<br>(Jum-Sen-Sel)</b></th>
                                    <th><b>Periode 2<br>(Rab-Kam)</b></th>
                                    <th><b>Total</b></th>
                                    <th><b>Sisa Stok Per Kamis<br>{{sisaStokKamis | moment}}</b></th>
                                    <th><b>Total Keperluan<br>(extra 10%)</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj, idx) in printObj" :key="idx">
                                    <td>{{obj.code}}</td>
                                    <td>{{obj.description}}</td>
                                    <td>{{obj.berat_buah_kg_p1 | rounded20}}</td>
                                    <td>{{obj.berat_buah_kg_p2 | rounded20}}</td>
                                    <td>{{Number(obj.berat_buah_kg_p1) + Number(obj.berat_buah_kg_p2) | rounded20}}</td>
                                    <td>{{obj.stok_aktual_awal | rounded20}}</td>
                                    <td>{{((Number(obj.berat_buah_kg_p1) + Number(obj.berat_buah_kg_p2))*1.1)-Number(obj.stok_aktual_awal) | rounded20}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <!-- </table> -->
                </div>
            </v-card-text>
        </v-card>
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

export default {

    data: () => ({
        dialog: false,
        onLoad: false,
        printObj: [],
        dateMenu: false,
        reportDate: null,
        reportCat: 'RAW',
        allowedDates: val => new Date(val+' 00:00:00').getDay() === 5,
        categories: [
            {code:'RAW', desc: 'Bahan Baku'},
            {code:'LNB', desc: 'Botol, Tutup, Label'}
        ]
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      sisaStokKamis() {
        const dt = new Date(this.reportDate + ' 00:00:00')
        dt.setDate(dt.getDate()-1)
        console.log(dt)
        return dt
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        },
    },

    async created() {
    },

    methods: {
        async runReport() {
            this.onLoad = true
            this.printObj = (await this.$store.dispatch('getObjs',{apidomain:'others/frm003/' + this.reportDate + '/', parameter:this.reportCat})).data
            console.log(this.printObj)
            this.onLoad = false
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        export2xls() {
            console.log(this.$refs.tableBOM.$el.firstChild.firstChild)
            const wb = utils.table_to_book(this.$refs.tableBOM.$el.firstChild.firstChild)
            writeFileXLSX(wb, "FRM-003.xlsx")

        },
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#printresep th, td {
  padding: 10px;
  font-size: 12px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
</style>